import React from 'react'
import ReconnectButton from 'src/components/ReconnectButton'
import { MiniRocket, AlertText } from './styles'

const DisconnectMenu = () => {
  return (
    <>
      <MiniRocket />
      <AlertText>Você está desconectado da RocketChat</AlertText>
      <ReconnectButton />
    </>
  )
}

export default DisconnectMenu
