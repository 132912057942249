const pages = [
  '/',
  '/wallboard',
  '/workshift',
  '/chatWithOutCaseList',
  '/featureflags',
  '/indicators',
]

export default pages
