import { action, computed } from 'easy-peasy'
import RocketModel from './types'

// Store dos todos
const rocketStore: RocketModel = {
  userId: null,
  authToken: null,
  isAuthenticated: computed(({ userId, authToken }) =>
    Boolean(userId && authToken)
  ),
  isPopupOpen: false,
  togglePopup: action((state) => {
    state.isPopupOpen = !state.isPopupOpen
  }),
  setPopup: action((state, payload) => {
    state.isPopupOpen = payload
  }),
  login: action((state, payload) => {
    const { authToken, userId } = payload
    state.authToken = authToken
    state.userId = userId
    localStorage.setItem('rocket-chat.authToken', authToken)
    localStorage.setItem('rocket-chat.userId', userId)
  }),
  logout: action((state) => {
    state.authToken = null
    state.userId = null
    state.isPopupOpen = false
    localStorage.removeItem('rocket-chat.authToken')
    localStorage.removeItem('rocket-chat.userId')
  }),
  start: action((state) => {
    const authToken = localStorage.getItem('rocket-chat.authToken')
    const userId = localStorage.getItem('rocket-chat.userId')
    if (authToken && userId) {
      state.authToken = authToken
      state.userId = userId
    }
  }),
}

export default rocketStore
