import React, { useRef, useState, useCallback } from 'react'
import { useStoreState } from 'src/lib/EasyPeasy'
import usePosition from 'src/helpers/hooks/usePosition'
import useMeasure from 'react-use/lib/useMeasureDirty'
import { Container, Circle, Name, StatusCircle } from './styles'
import Menu from './Menu'
import { getColorFromStatus, getInitialLetter } from './utils'

const Avatar = () => {
  // return null
  const email = useStoreState((s) => s.auth.email)
  const [isOpen, toggleOpen] = useState(false)
  const name = getInitialLetter(email)
  const ref = useRef<HTMLDivElement>(null)
  const { width, height } = useMeasure(ref)
  const { top, left } = usePosition(ref)
  const handleToggle = useCallback(() => {
  }, [toggleOpen])
  // const [status, setStatus] = useStatus()
  const status = 'online'
  return (
    <>
      <Container
        data-ignore-click-away
        onClick={handleToggle}
        selected={isOpen}
        ref={ref}
      >
        <Circle>
          <Name>{name}</Name>
          <StatusCircle color={getColorFromStatus(status)} />
        </Circle>
      </Container>
      <Menu
        isOpen={isOpen}
        toggle={toggleOpen}
        top={top + height}
        left={left + width}
      />
    </>
  )
}

export default Avatar
