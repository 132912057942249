import { action, thunk } from 'easy-peasy'
import { Auth } from 'aws-amplify'
import AuthModel from './types'

// Store dos todos
const authStore: AuthModel = {
  cognitoSetup: null,
  authenticated: false,
  loadingUser: true,
  user: null,
  email: null,
  groups: null,
  loadUser: action((state) => {
    state.loadingUser = true
  }),
  mfaSetup: action((state, payload) => {
    state.cognitoSetup = payload
  }),
  newPasswordRequired: action((state, payload) => {
    state.cognitoSetup = payload
  }),
  softwareTokenMfa: action((state, payload) => {
    state.cognitoSetup = payload
  }),
  logout: action((state) => {
    state.loadingUser = false
    state.user = null
  }),
  forgotPassword: action((state, payload) => {
    state.email = payload
  }),
  authUser: action((state, payload) => {
    state.user = payload
    state.email = payload.attributes.email
    state.groups = payload.signInUserSession.accessToken.payload['cognito:groups'] || []
    state.loadingUser = false
    state.authenticated = true
  }),
  currentAuthenticatedUser: thunk(async (actions) => {
    actions.loadUser()
    try {
      const user = await Auth.currentAuthenticatedUser()
      actions.authUser(user)
    } catch (err) {
      console.error(err)
      actions.logout()
    }
  }),
  signOut: thunk(async (actions) => {
    try {
      await Auth.signOut()
      actions.logout()
    } catch (err) {
      console.error(err)
      actions.logout()
    }
  }),
}

export default authStore
