
export const getColorFromStatus = (status: String) => {
  switch (status) {
    case 'online':
      return 'green'
    case 'offline':
      return 'gray'
    case 'away':
      return '#D2CA0D'
    case 'busy':
      return 'red'
    default: {
      return 'green'
    }
  }
}

export const nameFromStatus = (status: String) => {
  switch (status) {
    case 'online':
      return 'Online'
    case 'away':
      return 'Ausente'
    case 'busy':
      return 'Ocupado'
    case 'offline':
      return 'Invisivel'
    default:
      return 'Bugado'
  }
}

export const getInitialLetter = (email) => {
  const name = email.split('@')[0].split('.')

  return (
    name[0].charAt(0).toUpperCase() +
    (name[1] ? name[1].charAt(0).toUpperCase() : '')
  )
}
export const getName = (email) => {
  const name = email.split('@')[0]
  const firstName = name.split('.')[0]

  return firstName.charAt(0).toUpperCase() + firstName.slice(1)
}
