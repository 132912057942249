import React, { useState, useCallback, useEffect } from 'react'
import Select, { Option } from 'src/components/Select'
import Loading from 'src/components/Loading'
import { LoadingContainer } from './styles'
import { Projects, Intents, getProjects, getIntents, updateIntentPosition } from 'src/services'
import { useStoreState } from '../../lib/EasyPeasy'
import OverviewFlow from './Flow'
import IntentSidebar from './Sidebar'
import { Container, Button } from 'react-floating-action-button'

const Home = () => {
  const [agent, setAgent] = useState(null)
  const [agents, setAgents] = useState<Projects[]>([])
  const [intents, setIntents] = useState<Intents[] | undefined>(undefined)
  const [intent, setIntent] = useState<Intents | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const [isFrameLoading, setIsFrameLoading] = useState(true)
  const [isPanelVisible, setIsPanelVisible] = useState(false)
  const [elements, setElements] = useState([])
  const token = useStoreState((s) => s.auth.user.signInUserSession.idToken.jwtToken)

  // const elements = [
  //   { id: 'e1-2', source: '1', target: '2', animated: true },
  // ];

  useEffect(() => {
    getProjects(token).then(r => {
      if (r.data) {
        setAgents(r.data.projects)
        setIsLoading(false)
      }
    })
  }, [token])

  const handleAgentSelect = useCallback(async (e) => {
    console.log('select')
      setIsFrameLoading(true)
      setAgent(e.target.value)
      getIntents(token, e.target.value).then(r => {
        if (r.data) {
          const dfIntents = r.data.intents
          let els : any[] = []
          const evts : any[] = []
          dfIntents.forEach(i => {
            let position;
            try {
              position = JSON.parse(i.action)
            } catch (err) {
              console.log('err')
              position = { x: 0, y: 0 }
            }
            els.push({
              id: i.displayName,
              data: {
                label: i.displayName
              },
              position,
              sourcePosition: 'right',
              targetPosition: 'left'
            })
  
            getEventsOnPayload(i).forEach(ev => {
              evts.push({
                id: `${i.displayName}-${ev}`, 
                source: i.displayName, 
                target: ev
              })
            })
          })
  
          els = els.concat(evts)
          setElements(els as any)
          setIntents(dfIntents as any)
          setIsFrameLoading(false)
        }
      })
    },
    [agent, intents, elements, isLoading, isFrameLoading]
  )
  
  const getEventsOnPayload = (intent) => {
    const evts : any[] = [];
    const evrxp = /event\(.*?\)/g;
    if (intent.messages) {
        intent.messages.forEach(function(item) {
            if (item.payload 
                && item.payload.action 
                && item.payload.action.type == "javascript" 
                && item.payload.action.command) {
                    let tmp = item.payload.action.command.match(evrxp) ? item.payload.action.command.match(evrxp) : [];
                    tmp.map(txt => txt.replace(/event\(/g, "").replace(/_/g, "-").replace(txt.substring(txt.split("\"", 2).join("\"").length), "").replace("\"", "")).forEach(item => evts.push(item));
            }
        });
    }
    return evts;
  }

  const onElementClick = useCallback(
    (ev, el) => {
      if (intents && el.id !== 'atendimento-humano') {
        const it = intents.find(i => i.displayName === el.id)
        setIntent(it)
        setIsPanelVisible(true)
      }      
    },
    [intents, intent, isPanelVisible]
  )

  const onNewIntentClick = useCallback(
    () => {
      const it = {
        id: "new",
        displayName: "new"
      }
      setIntent(it as any)
      setIsPanelVisible(true)
    }, [intent, isPanelVisible]
  )

  const onElementDrag = (ev, el) => {
    if (intents) {
      const it = intents.find(i => i.displayName === el.id)
      updateIntentPosition(token, it ? it.name : null, el.position)
      console.log("q")
    }
  }
  
  return (
    isLoading ? 
      <LoadingContainer><Loading/></LoadingContainer> 
      :
      <>
      <Select
        options={agents.map((e) => ({ value: e.projectId as string, label: e.name as string }))}
        value={agent}
        onChange={handleAgentSelect}
        label='Selecione um Bot'/>  
        { isFrameLoading ? <></> : <OverviewFlow els={elements} onElementClick={onElementClick} onDragStop={onElementDrag}/> }
        <IntentSidebar visible={isPanelVisible} intent={intent} onClose={() => {setIsPanelVisible(false)}} onSave={() => {}}/>
        { isFrameLoading ? <></> : 
          <Container style={{bottom: '1vh', right: '1vw', zIndex: '14000'}} className={'applyZIndex'}>
            <Button
                tooltip="Criar nova intenção"
                icon="fas fa-plus"
                rotate={false}
                onClick={onNewIntentClick} />
          </Container>
        }
      </>
    )
}

export default Home
