import { FormControl } from '@material-ui/core'
import styled from 'src/lib/StyledComponents'

export const StyledFormControl = styled(FormControl)`
  &.MuiFormControl-root {
    /* width: 90%; */
    margin: 10px 10px;
    display: inline flex !important;
  }

  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
    /* width: 100% !important; */
    min-width: 300px
  }
`
