import React, { useState } from 'react';
import ReactFlow, {
  removeElements,
  addEdge,
  MiniMap,
  Controls,
  Background,
} from 'react-flow-renderer';

const onLoad = (reactFlowInstance) => {
  console.log('flow loaded:', reactFlowInstance);
  reactFlowInstance.fitView();
};

const OverviewFlow = ({ els, onElementClick, onDragStop }) => {
  const [elements, setElements] = useState(els);
  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els));
  const onConnect = (params) => setElements((els) => addEdge(params, els));
  return (
    <ReactFlow
      elements={elements}
      onElementsRemove={onElementsRemove}
      onElementClick={onElementClick}
      onConnect={onConnect}
      onNodeDragStop={onDragStop}
      onLoad={onLoad}
      snapToGrid={true}
      snapGrid={[15, 15]}
      elementsSelectable={true}
      zoomOnScroll={true}
    >
      <Controls />
      <Background color="#aaa" gap={16} />
    </ReactFlow>
  );
};

export default OverviewFlow;