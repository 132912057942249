import React, { useMemo } from 'react'
import { GlobalHotKeys } from 'react-hotkeys'
import { useStoreActions } from 'src/lib/EasyPeasy'

const keyMap = {
  TOGGLE_TABLE_BORDER: ['ctrl+shift+q', 'command+shift+q'],
}

const HotKeysWrapper: React.FC = ({ children }) => {
  const toggleTableBorder = useStoreActions(
    (s) => s.accessibility.toggleTableBorders
  )

  const handlers = useMemo(
    () => ({
      TOGGLE_TABLE_BORDER: () => toggleTableBorder(),
    }),
    [toggleTableBorder]
  )

  return (
    <GlobalHotKeys handlers={handlers} keyMap={keyMap}>
      {children}
    </GlobalHotKeys>
  )
}

export default HotKeysWrapper
