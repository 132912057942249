import Axios from 'axios'
import { Pagination } from 'src/helpers/hooks/types'

const { REACT_APP_FINTALK_ENDPOINT, REACT_APP_JARVIS_SOCKET_ENDPOINT, NODE_ENV } = process.env

const api = Axios.create({
  baseURL: `http${
    NODE_ENV === 'production' ? 's' : ''
  }://${REACT_APP_JARVIS_SOCKET_ENDPOINT}`,
})

const fintalkapi = Axios.create({
  baseURL: `https://${REACT_APP_FINTALK_ENDPOINT}`
})

export default fintalkapi

export interface ProjectsResponse {
  projects: Projects [],
}

export interface IntentsResponse {
  intents: Intents [],
}

export interface Projects {
  projectId: string,
  name: string,
}

export interface Intents {
  name: string,
  displayName: string,
  action: string,
  trainingPhrases: TrainingPhrase[], 
}

export interface TrainingPhrase {
  name: string,
  parts: TrainingPhrasePart[],
}

export interface TrainingPhrasePart {
  text: string,
  entityType: string,
}

export interface CSATResponse {
  _id: string
  email: string
  month: string
  csat: number
}

export interface CSATAnswerResponse {
  _id: string
  room_id: string
  score: number
  message: string
  date_resp: string
  email: string
}

export interface CRLResponse {
  _id: string
  month: string
  crl: number
  email: string
}

export interface CRLCaseResponse {
  _id: string
  case: string
  tree: string
  status: string
  email: string
  solve_date: string
}

export interface QualityEvaluation {
  _id: string
  week_of_evaluation: string
  final_score: string
  case_subject: string
  quality_name: string
  email: string
  case_id: string
  date_reference: string
}

export interface QualityReport {
  _id: string
  email: string
  month: string
  final_score: string
}

export interface ProductivityDemand {
  _id: string
  total_demands: number
  channel: number
  membro: number
  email: string
  date: string
  working_hours: number
}

export interface Productivity {
  channel: number
  email: string
  id: string
  month: string
  productivity: number
  productivity_channel: number
  productivity_member: number
  total_demands: number
  working_hours: number
  _id: string
}

export const getProjects = (token) => 
  fintalkapi.get<ProjectsResponse | null>(
    '', {
      headers: {
        Authorization: token,
        url: 'https://cloudresourcemanager.googleapis.com/v1/projects'
      }
    }
  )

export const getIntents = (token, project) => 
  fintalkapi.get<IntentsResponse | null>(
    '', {
      headers: {
        Authorization: token,
        url: `/list/${project}`
      }
    }
  )

export const updateIntentPosition = (token, intent, position) => {
  fintalkapi.patch(
    '', {
      action: JSON.stringify(position)
    }, {
      headers: {
        Authorization: token,
        url: `https://dialogflow.googleapis.com/v2/${intent}?updateMask=action`,
      }
    }
  )
}

export const getCsat = (email: string, month?: number) =>
  api.get<CSATResponse | null>(
    `/agent/csat?email=${email}${month ? `&month=${month}` : ''}`
  )

export const getCsatAnswers = (email: string, page: number, month?: number) =>
  api.get<Pagination<CSATAnswerResponse>>(
    `/agent/csat_answers?email=${email}&page=${page}${
      month ? `&month=${month}` : ''
    }`
  )

export const getCrl = (email: string, month?: number) =>
  api.get<CRLResponse | null>(
    `/agent/crl?email=${email}${month ? `&month=${month}` : ''}`
  )

export const getCrlCase = (email: string, page: number, month?: number) =>
  api.get<Pagination<CRLCaseResponse>>(
    `/agent/crl_case?email=${email}&page=${page}${
      month ? `&month=${month}` : ''
    }`
  )

export const getQualityEvaluations = (
  email: string,
  page: number,
  month?: number
) =>
  api.get<Pagination<QualityEvaluation>>(
    `/agent/quality_evaluation?email=${email}&page=${page}${
      month ? `&month=${month}` : ''
    }`
  )

export const getQualityReport = (email: string, month?: number) =>
  api.get<QualityReport | null>(
    `/agent/quality_report?email=${email}${month ? `&month=${month}` : ''}`
  )

export const getProductivityDemands = (
  email: string,
  page: number,
  month?: number
) =>
  api.get<Pagination<ProductivityDemand>>(
    `/agent/productivity_demands?email=${email}&page=${page}${
      month ? `&month=${month}` : ''
    }`
  )

export const getProductivity = (email: string, month?: number) =>
  api.get<Productivity | null>(
    `/agent/productivity?email=${email}${month ? `&month=${month}` : ''}`
  )
