const theme = {
  // heights
  h5: {
    height: '5vh',
  },
  h10: {
    height: '10vh',
  },
  // positioning
  vertAlignMiddle: {
    verticalAlign: 'middle',
  },
  centerVertically: {
    margin: 'auto 0',
  },
  centerRight: {
    margin: 'auto 0 auto auto',
  },
  centerLeft: {
    margin: 'auto auto auto 0',
  },
  center: {
    margin: 'auto',
  },
  positionRelative: {
    position: 'relative',
  },
  absoluteTopRight: {
    position: 'absolute',
    top: '0px',
    right: '0px',
  },
  // displays
  displayContents: {
    display: 'contents',
  },
  displayFlex: {
    display: 'flex',
  },
  displayBlock: {
    display: 'block',
  },
  displayInlineBlock: {
    display: 'inline-block',
  },
  displayInlineFlex: {
    display: 'inline-flex',
  },
  // borders
  rounded: {
    borderRadius: '5px',
  },
  whiteBorder: {
    border: '1px solid #FFFFFF10',
  },
  lightBorder: {
    border: '1px solid #00000010',
  },
  // bgs
  bgWhite: {
    background: '#FFF',
  },
  bgGray: {
    background: '#F4F3FA',
  },
  bgPrimary: {
    background: '#54ECB1',
  },
  bgSecondary: {
    background: '#46C677',
  },
  bgTertiary: {
    background: '#39975E',
  },
  // colors
  colorWhite: {
    color: '#FFF',
  },
  colorGray: {
    color: '#F4F3FA',
  },
  colorDarkGray: {
    color: '#74737A',
  },
  colorPrimary: {
    color: '#54ECB1',
  },
  colorSecondary: {
    color: '#46C677',
  },
  colorTertiary: {
    color: '#39975E',
  },
  colorDanger: {
    color: '#F86464',
  },
  colorWarning: {
    color: '#FFBF00',
  },
  colorSuccess: {
    color: '#46C677',
  },
  // object specifications
  icon: {
    width: '24px',
    height: '24px',
    '& img': {
      width: '100%',
      height: '100%',
    },
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  panel: {
    padding: '1rem',
  },
  innerPanel: {
    margin: 'auto',
  },
  card: {
    padding: '0.5rem',
  },
  btn: {
    margin: '0px 5px 0px 5px',
  },
  // shadows
  hasShadow: {
    boxShadow: '1px 1px 5px 0px #00000010',
  },
  // padding
  p0: {
    padding: '0px',
  },
  p5: {
    padding: '5px',
  },
  pt5: {
    paddingTop: '5px',
  },
  gridPadding: {
    padding: '0.5rem 1.0rem',
  },
  // overflow
  overflowHidden: {
    overflow: 'hidden',
  },
  // widthhelpers
  fullWidth: {
    width: '100%',
  },
  halfWidth: {
    width: '50%',
  },
  // cursor
  clickable: {
    cursor: 'pointer',
  },
  // actions
  selected: {
    boxShadow: '0px 0px 0px 1px #54ECB1',
  },
  // textHelpers
  centerText: {
    textAlign: 'center',
  },
  // fontSizes
  fontXL: {
    fontSize: 'xxx-large',
  },
  fontLG: {
    fontSize: 'large',
  },
  fontMD: {
    fontSize: 'medium',
  },
  fontSM: {
    fontSize: 'small',
  },
  fontXS: {
    fontSize: 'xxx-small',
  },
  // transformations
  rotate180: {
    transform: 'rotate(180deg)',
  },
  // animations
  slideInTop: {
    animation: '$slideInTop 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
  },
  '@keyframes slideInTop': {
    '0%': {
      transform: 'translateY(-1000px)',
      filter: 'blur(50px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0)',
      filter: 'blur(0)',
      opacity: 1,
    },
  },
  slideInRight: {
    animation:
      '$slideInRight 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
  },
  '@keyframes slideInRight': {
    '0%': {
      transform: 'translateX(1000px)',
      filter: 'blur(50px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateX(0)',
      filter: 'blur(0)',
      opacity: 1,
    },
  },
  slideInLeft: {
    animation:
      '$slideInLeft 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
  },
  '@keyframes slideInLeft': {
    '0%': {
      transform: 'translateX(-1000px)',
      filter: 'blur(50px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateX(0)',
      filter: 'blur(0)',
      opacity: 1,
    },
  },
}
export default theme
