import styled from 'src/lib/StyledComponents'
import { Button as BaseButton } from '@material-ui/core'

export const Button = styled(BaseButton).attrs({
  variant: 'contained',
  size: 'large',
  color: 'primary',
})`
  &.MuiButtonBase-root {
    margin: 10px auto;
    width: 90%;
    display: block;
  }
`
