import React from 'react'
import { MuiThemeProvider } from '@material-ui/core'
import { Auth } from 'aws-amplify'
import { ThemeProvider } from 'src/lib/StyledComponents'
import Routes from './Routes'
import { useStoreActions } from './lib/EasyPeasy'
import { muiTheme, theme } from './lib/theme'

const App = () => {
  const authUser = useStoreActions((s) => s.auth.authUser)
  const logout = useStoreActions((s) => s.auth.logout)
  React.useEffect(() => {
    const fetchInitialUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        authUser(user)
      } catch (error) {
        logout()
      }
    }
    fetchInitialUser()
  }, [authUser, logout])

  return (
    <MuiThemeProvider theme={muiTheme}>
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </MuiThemeProvider>
  )
}

export default App
