import React from 'react'
import { Paper, Typography, Button } from '@material-ui/core'
import { Formik } from 'formik'
import { Auth } from 'aws-amplify'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useStoreActions } from 'src/lib/EasyPeasy'
import FormTextField from './FormTextField'

const useStyles = makeStyles({
  loginContainer: {
    minHeight: '100vh',
    backgroundColor: '#FF7063',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formContainer: {
    width: 390,
    padding: 48,
  },
})

const handleLoginError = (error) => {
  switch (error.code) {
    default:
      alert(error.message)
  }
}

const ForgotPassword = () => {
  const classes = useStyles()
  const history = useHistory()
  const forgotPassword = useStoreActions((s) => s.auth.forgotPassword)
  const onSubmit = async ({ email }) => {
    try {
      await Auth.forgotPassword(email)
      forgotPassword(email)
      history.push('/forgot-password-submit')
    } catch (error) {
      console.log(error)
      handleLoginError(error)
    }
  }

  return (
    <div className={classes.loginContainer}>
      <Paper className={classes.formContainer}>
        <Typography align='center' variant='h4' gutterBottom>
          Esqueceu sua senha?
        </Typography>
        <Formik
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormTextField
                label='Email'
                name='email'
                fullWidth
                margin='normal'
              />
              <Button
                type='submit'
                color='primary'
                variant='contained'
                size='large'
                fullWidth
              >
                Enviar
              </Button>
            </form>
          )}
        />
      </Paper>
    </div>
  )
}

export default ForgotPassword
