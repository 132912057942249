import React, { useCallback } from 'react'
import Avatar from 'src/components/Avatar'
import { verifyUserGroup } from 'src/helpers/usergroups'
import Dashboard from '@material-ui/icons/Dashboard'
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation'
import ListAltIcon from '@material-ui/icons/ListAlt'
import ToggleOnIcon from '@material-ui/icons/ToggleOn'
// import PieChartIcon from '@material-ui/icons/PieChart'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import useEmbedded from 'src/helpers/hooks/useEmbedded'
import { useStoreActions, useStoreState } from 'src/lib/EasyPeasy'
import { useMedia } from 'react-use'
import { useHistory } from 'react-router-dom'
import {
  Container,
  TopSection,
  Logo,
  BottomSection,
  Wrapper,
  Main,
  Column,
} from './styles'
import Items, { Item } from './Items'

const Dash: React.FC = ({ children }) => {
  const embedded = useEmbedded()
  const isSmall = useMedia('(max-width:500px)')
  const signOut = useStoreActions((s) => s.auth.signOut)
  const userGroups = useStoreState((s) => s.auth.groups)
  const handleExit = useCallback(() => {
    signOut()
  }, [signOut])
  const history = useHistory()
  const handleLogoClick = useCallback(() => {
    history.push('/')
  }, [history])
  return (
    <Wrapper>
      {!embedded && (
        <Container>
          <TopSection>
            {!isSmall && <Logo onClick={handleLogoClick} />}
            <Items>
              <Item to='/'>
                <Dashboard />
              </Item>
              {/* <Item to='/indicators'>
                <PieChartIcon />
              </Item> */}
            </Items>
          </TopSection>
          <BottomSection>
            <Avatar />
            <Item onClick={handleExit}>
              <ExitToAppOutlinedIcon />
            </Item>
          </BottomSection>
        </Container>
      )}
      <Column>
        <Main>
          {children}
        </Main>
      </Column>
    </Wrapper>
  )
}

export default Dash
