import React from 'react'
import { InputLabel, MenuItem, Select as SelectItem } from '@material-ui/core'
import FormHelperText from '@material-ui/core/FormHelperText'
import { StyledFormControl } from './styles'

export interface Option<T> {
  label: string
  value: T
}

export interface Props<T = any> {
  options: Option<T>[]
  label: string
  value: T
  onChange: (_: React.ChangeEvent<{ value: T }>, newValue: T) => void
  error?: any
}

const Select: React.FC<Props> = ({
  options,
  label,
  value,
  onChange,
  error = null,
  ...props
}) => {
  return (
    <StyledFormControl
      size='small'
      variant='outlined'
      error={Boolean(error)}
      {...props}
    >
      <InputLabel>{label}</InputLabel>
      <SelectItem value={value} onChange={onChange} label={label}>
        {options.map((option, index) => (
          <MenuItem value={option.value} key={index}>
            {option.label}
          </MenuItem>
        ))}
      </SelectItem>
      {error && <FormHelperText>{error}</FormHelperText>}
    </StyledFormControl>
  )
}

export default Select
