import styled, { css } from 'src/lib/StyledComponents'

const size = 30

const division = (s: number, quantity: number) => Math.floor(s / quantity)

const statusSize = division(size, 3)

export const StatusCircle = styled.div<{ color: string }>`
  width: ${statusSize}px;
  height: ${statusSize}px;
  border-radius: 50%;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: background-color;
  background-color: ${(p) => p.color};
  position: absolute;
  bottom: -5px;
  right: -5px;
  border: 3px solid #00ecb1;
`

const darkenSelection = css`
  background-color: rgba(0, 0, 0, 0.04);
  ${StatusCircle} {
    border-color: #00e2ad;
  }
`

export const Container = styled.div<{ selected?: boolean }>`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px 0;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: background-color;
  &:hover {
    ${darkenSelection}
  }
  ${(p) => p.selected && darkenSelection};
  @media screen and (max-width: 500px) {
    padding: 5px;
  }
`

export const Circle = styled.div`
  position: relative;
  margin: 5px auto;
  border-radius: 50%;
  width: ${size}px;
  height: ${size}px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #e3e3e3;
`

export const Name = styled.p`
  user-select: none;
  font-size: ${size / 2.5}px;
`
