import React, { useEffect, useRef, useState } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import {
  Login,
  SoftwareTokenMfa,
  NewPasswordRequired,
  MfaSetup,
  ForgotPassword,
  ForgotPasswordSubmit,
} from './pages/auth'
// import Wallboard from './pages/wallboard'
// import Workshift from './pages/workshift'
// import ChatList from './pages/chatList'
import Home from './pages/home'
import Dash from './components/Dash'
import { useStoreState } from './lib/EasyPeasy'
// import FullpageLoader from './components/FullpageLoader'
// import FeatureFlags from './pages/featureflags'
// import Indicators from './pages/indicators'

const Routes = () => {
  const user = useStoreState((s) => s.auth.user)
  const loadingUser = useStoreState((s) => s.auth.loadingUser)
  const [loading, setLoading] = useState(true)
  const timer = useRef<number>()
  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current as any)
    }
    timer.current = setTimeout(() => {
      setLoading(false)
    }, 1000) as any
  }, [setLoading])
  const history = useHistory()
  useEffect(() => {
    if (!user && !loadingUser) {
      history.push('/login')
    }
  }, [user, loadingUser, history])

  return (
    <>
      {/* <FullpageLoader isLoading={loadingUser || loading} /> */}

      <Switch>
        <Route path='/login'>
          <Login />
        </Route>
        <Route path='/software-token-mfa'>
          <SoftwareTokenMfa />
        </Route>
        <Route path='/new-password-required'>
          <NewPasswordRequired />
        </Route>
        <Route path='/mfa-setup'>
          <MfaSetup />
        </Route>
        <Route path='/forgot-password'>
          <ForgotPassword />
        </Route>
        <Route path='/forgot-password-submit'>
          <ForgotPasswordSubmit />
        </Route>

        {user && (
          <Dash>
            <Route path='/' exact>
              <Home />
            </Route>
            {/* <Route path='/wallboard'>
              <Wallboard />
            </Route>
            <Route path='/workshift'>
              <Workshift />
            </Route>
            <Route path='/chatWithOutCaseList'>
              <ChatList />
            </Route>
            <Route path='/featureflags'>
              <FeatureFlags />
            </Route>
            <Route path='/indicators'>
              <Indicators />
            </Route> */}
          </Dash>
        )}
      </Switch>
    </>
  )
}

export default Routes
