import rocketStore from './rocket'
import authStore from './auth'
import { StoreModel } from '../types'
import headersStore from './headers'
import rootStore from './root'
import indicatorsStore from './indicators'
import accessibilityStore from './accessibility'

// Store Global
const storeModel: StoreModel = {
  auth: authStore,
  rocket: rocketStore,
  headers: headersStore,
  indicators: indicatorsStore,
  accessibility: accessibilityStore,
  ...rootStore,
}

export default storeModel
