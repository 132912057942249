import React from 'react'
import { useStoreState } from 'src/lib/EasyPeasy'
import { Title, Status as StatusIndicator, Line, Exit } from '../styles'
import { nameFromStatus, getName } from '../../utils'

interface Props {
  status: String
  exit: () => void
}

const StatusMenu: React.FC<Props> = ({ status, exit }) => {
  const email = useStoreState((s) => s.auth.email)
  const name = getName(email)
  return (
    <>
      <Title>{name}</Title>
      <StatusIndicator status={status} selectable={false}>
        {nameFromStatus(status)}
      </StatusIndicator>
      <Line />
      <Title>Status</Title>
      <StatusIndicator status='online'>
        Online
      </StatusIndicator>
      <Line />
      <Exit
        onClick={() => {
          exit()
        }}
      >
        Sair
      </Exit>
    </>
  )
}
export default StatusMenu
