import styled from 'src/lib/StyledComponents'

export const MiniRocket = styled.img.attrs({
  src: '/icons/mini-rocket-chat.svg',
})`
  margin: 5px auto;
  margin-top: 10px;
`

export const AlertText = styled.p`
  text-align: center;
  box-sizing: border-box;
  padding: 0 5px;
  margin: 5px auto;
  max-width: 200px;
`
