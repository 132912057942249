import React from 'react'
import { Paper, Typography, Button } from '@material-ui/core'
import { Auth } from 'aws-amplify'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useStoreState, useStoreActions } from 'src/lib/EasyPeasy'
import FormTextField from './FormTextField'

const useStyles = makeStyles({
  loginContainer: {
    minHeight: '100vh',
    backgroundColor: '#FF7063',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formContainer: {
    width: 390,
    padding: 48,
  },
})

const handleLoginError = (error) => {
  switch (error.code) {
    default:
      alert(error.message)
  }
}

const NewPasswordRequired = () => {
  const classes = useStyles()
  const cognitoState = useStoreState((s) => s.auth.cognitoSetup)
  const mfaSetup = useStoreActions((s) => s.auth.mfaSetup)
  const currentAuthenticatedUser = useStoreActions(
    (s) => s.auth.currentAuthenticatedUser
  )
  const history = useHistory()

  if (!cognitoState) {
    history.push('/login')
  }

  const changePassword = async ({ password }) => {
    try {
      const cognitoUser = await Auth.completeNewPassword(cognitoState, password)

      if (cognitoUser.challengeName === 'MFA_SETUP') {
        mfaSetup(cognitoUser)
        history.push('/mfa-setup')
      } else {
        currentAuthenticatedUser()
        history.push('/')
      }
    } catch (error) {
      console.log(error)
      handleLoginError(error)
    }
  }
  return (
    <div className={classes.loginContainer}>
      <Paper className={classes.formContainer}>
        <Typography align='center' variant='h4' gutterBottom>
          Altere sua senha
        </Typography>
        <Formik
          initialValues={{
            password: '',
          }}
          onSubmit={changePassword}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormTextField
                label='Senha'
                name='password'
                type='password'
                fullWidth
                margin='normal'
              />
              <Button
                type='submit'
                color='primary'
                variant='contained'
                size='large'
                fullWidth
              >
                Entrar
              </Button>
            </form>
          )}
        />
      </Paper>
    </div>
  )
}
export default NewPasswordRequired
