enum Conditions {
    if,
    else,
}

enum Clausules {
    userInput,
    variable,
}

enum Actions {
    setVar,
    msg,
    event,
}

enum Types {
    text,
    image,
    video,
    document
}

export interface Action {
    action: Actions,
    clausule: Clausules,
    type: Types,
    value: string,
}

export interface Condition {
    condition: Conditions,
    clausule: Clausules,
    variable: string,
    value: string,
    actions: Array<Action>,
}

export class Payload {
    payload: Array<Condition|Action>

    constructor(payload: string) {
        this.payload = []
    }

    pushCondition(condition: Condition) {
        this.payload.push(condition)
    }

    pushAction(action: Action) {
        this.payload.push(action)
    }

    getPayload() {
        return this.payload
    }
}

