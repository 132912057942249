import React from 'react'
import { Paper, Typography, Button } from '@material-ui/core'
import { Formik } from 'formik'
import { Auth } from 'aws-amplify'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useStoreActions, useStoreState } from 'src/lib/EasyPeasy'
import FormTextField from './FormTextField'

const useStyles = makeStyles({
  loginContainer: {
    minHeight: '100vh',
    backgroundColor: '#FF7063',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formContainer: {
    width: 390,
    padding: 48,
  },
})

const handleLoginError = (error) => {
  switch (error.code) {
    default:
      alert(error.message)
  }
}

const ForgotPasswordSubmit = () => {
  const classes = useStyles()
  const email = useStoreState((s) => s.auth.email)
  const history = useHistory()
  const currentAuthenticatedUser = useStoreActions(
    (s) => s.auth.currentAuthenticatedUser
  )

  if (!email) {
    history.push('/forgot-password')
  }

  const onSubmit = async ({ code, password }) => {
    try {
      await Auth.forgotPasswordSubmit(email, code, password)

      currentAuthenticatedUser()
      history.push('/')
    } catch (error) {
      console.log(error)
      handleLoginError(error)
    }
  }

  return (
    <div className={classes.loginContainer}>
      <Paper className={classes.formContainer}>
        <Typography align='center' variant='h4' gutterBottom>
          Código
        </Typography>
        <Formik
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormTextField
                label='Código'
                name='code'
                fullWidth
                margin='normal'
              />
              <FormTextField
                label='Senha'
                name='password'
                type='password'
                fullWidth
                margin='normal'
              />
              <Button
                type='submit'
                color='primary'
                variant='contained'
                size='large'
                fullWidth
              >
                Enviar
              </Button>
            </form>
          )}
        />
      </Paper>
    </div>
  )
}

export default ForgotPasswordSubmit
