import { useEffect, useState } from 'react'

const useEmbedded = (): boolean => {
  const [embedded, setEmbedded] = useState(false)
  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.get('layout') === 'embedded') {
      setEmbedded(true)
    }
  }, [])

  return embedded
}

export default useEmbedded
