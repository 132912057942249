import { AxiosResponse } from 'axios'
import { action, thunk, thunkOn } from 'easy-peasy'
import { Pagination } from 'src/helpers/hooks/types'
import { PaginatedStore, ValuedStore } from './types'

type Fetcher<T> = (
  email: string,
  page: number,
  month?: number
) => Promise<AxiosResponse<Pagination<T>>>

export const getPaginatedStore = <T = any>(
  fetcher: Fetcher<T>
): PaginatedStore<T> => ({
  loading: true,
  setLoading: action((state, newLoading) => {
    state.loading = newLoading
  }),
  docs: [],
  limit: 0,
  page: 1,
  totalPages: 1,
  loadPage: thunk(async (actions, { email, month }, { getState }) => {
    const { page } = getState()
    try {
      const { data } = await fetcher(email, page, month)
      actions.setAll(data)
      actions.setLoading(false)
    } catch {
      actions.setAll({
        docs: [],
        limit: 0,
        page: 1,
        totalPages: 1,
      })
      actions.setLoading(false)
    }
  }),
  setAll: action((state, payload) => {
    const { docs, page, limit, totalPages } = payload
    state.docs = docs
    state.page = page
    state.limit = limit
    state.totalPages = totalPages
  }),
  setPage: action((state, newPage) => {
    state.page = newPage
  }),
  listenPageChange: thunkOn(
    (actions) => actions.setPage,
    (actions, _, { getStoreState }) => {
      const {
        indicators: { email, month },
      } = getStoreState()
      actions.loadPage({ email, month })
    }
  ),
})
type ValueFetcher = (
  email: string,
  month?: number
) => Promise<AxiosResponse<any>>

export const getValuedStore = <T>(
  fetcher: ValueFetcher,
  defaultValue: T,
  path?: string
): ValuedStore<T> => ({
  value: defaultValue,
  setValue: action((state, newValue) => {
    state.value = newValue
  }),
  loading: true,
  setLoading: action((state, newLoading) => {
    state.loading = newLoading
  }),
  loadValue: thunk(async (actions, { email, month }) => {
    const { data } = await fetcher(email, month)
    if (data) {
      if (path) {
        actions.setValue(parseFloat(data[path]))
      } else {
        actions.setValue(data)
      }
    } else {
      actions.setValue(0.0)
    }
    actions.setLoading(false)
  }),
})
