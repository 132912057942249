import React from 'react'
import ReactDOM from 'react-dom'
import Amplify, { Auth } from 'aws-amplify'
import { BrowserRouter as Router } from 'react-router-dom'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from '@apollo/react-hooks'
import { StoreProvider, createStore } from 'easy-peasy'
import App from './App'
import * as serviceWorker from './serviceWorker'
import storeModel from './store/models'
import GlobalStyles from './lib/global'
import HotKeysWrapper from './components/HotKeysWrapper'

if (module.hot) {
  module.hot.accept()
}
const {
  REACT_APP_COGNITO_REGION,
  REACT_APP_COGNITO_USER_POOL_ID,
  REACT_APP_COGNITO_APP_CLIENT_ID,
} = process.env

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: REACT_APP_COGNITO_REGION,
    userPoolId: REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_COGNITO_APP_CLIENT_ID,
  },
})

const client = new ApolloClient({
  request: async (operation) => {
    const auth = await Auth.currentSession()
    const token = auth.getIdToken().getJwtToken()
    operation.setContext({
      headers: {
        authorization: token ? `${token}` : '',
      },
      uri: `${process.env.REACT_APP_API_ADMIN_ENDPOINT}/graphql`,
    })
  },
})

const store = createStore(storeModel, {
  injections: {
    apollo: client,
  },
  devTools: process.env.NODE_ENV === 'development',
})

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept('./store/models', () => {
      store.reconfigure(storeModel) // 👈 Here is the magic
    })
  }
}

ReactDOM.render(
  <StoreProvider store={store}>
      <ApolloProvider client={client}>
        <HotKeysWrapper>
          <Router>
            <App />
            <GlobalStyles />
          </Router>
        </HotKeysWrapper>
      </ApolloProvider>
  </StoreProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
