import React, { useCallback } from 'react'
import { useStoreActions } from 'src/lib/EasyPeasy'
import { Button } from './styles'

const ReconnectButton = () => {
  const toggleLoginPopup = useStoreActions((s) => s.rocket.setPopup)
  const toggle = useCallback(() => {
    toggleLoginPopup(true)
  }, [toggleLoginPopup])
  return <Button onClick={toggle}>Reconectar</Button>
}

export default ReconnectButton
