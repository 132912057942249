import { action, persist } from 'easy-peasy'
import {
  CRLCaseResponse,
  CSATAnswerResponse,
  getCrl,
  getCrlCase,
  getCsat,
  getCsatAnswers,
  getProductivity,
  getProductivityDemands,
  getQualityEvaluations,
  getQualityReport,
  Productivity,
  ProductivityDemand,
  QualityEvaluation,
} from 'src/services'
import { getPaginatedStore, getValuedStore } from './substore'
import { IndicatorsStoreModel } from './types'

const indicatorsStore: IndicatorsStoreModel = {
  email: '',
  month: new Date().getMonth() + 1,
  setMonth: action((state, newMonth) => {
    state.month = newMonth
  }),
  year: new Date().getFullYear(),
  setYear: action((state, newYear) => {
    state.year = newYear
  }),
  setEmail: action((state, newEmail) => {
    state.email = newEmail
  }),
  quality: {
    report: getValuedStore(getQualityReport, 0, 'final_score'),
    evaluation: getPaginatedStore<QualityEvaluation>(getQualityEvaluations),
  },
  crl: {
    value: getValuedStore(getCrl, 0, 'crl'),
    cases: getPaginatedStore<CRLCaseResponse>(getCrlCase),
  },
  csat: {
    value: getValuedStore(getCsat, 0, 'csat'),
    answers: getPaginatedStore<CSATAnswerResponse>(getCsatAnswers),
  },
  productivity: {
    value: getValuedStore<Productivity>(getProductivity, {
      _id: '',
      email: '',
      month: '',
      productivity: 0,
      channel: 0,
      id: '',
      productivity_channel: 0,
      productivity_member: 0,
      total_demands: 0,
      working_hours: 0,
    }),
    demands: getPaginatedStore<ProductivityDemand>(getProductivityDemands),
  },
}

export default persist(indicatorsStore, {
  whitelist: ['year', 'month', 'email'],
  storage: 'localStorage',
})
