import React, { useRef, useCallback } from 'react'
import { Portal } from 'react-portal'
import { useTransition } from 'react-spring'
import { useClickAway, useKey, useMedia } from 'react-use'
import { useStoreState, useStoreActions } from 'src/lib/EasyPeasy'
import { Container, ContentBody } from './styles'
import StatusMenu from './StatusMenu'
import DisconnectMenu from './DisconnectMenu'

interface Props {
  top: number
  left: number
  isOpen: boolean
  toggle: React.Dispatch<React.SetStateAction<boolean>>
}

const Menu: React.FC<Props> = ({ top, left, isOpen, toggle }) => {
  const ref = useRef<HTMLDivElement>(null)
  const status = 'online'
  useClickAway(ref, (event: any) => {
    const isParent = event.path.some(
      (element) =>
        element.attributes && element.attributes['data-ignore-click-away']
    )
    if (!isParent) {
      toggle(false)
    }
  })
  useKey('Escape', () => {
    toggle(false)
  })
  const isInverted = useMedia('(max-width: 500px)')
  const transitions = useTransition(isOpen, null, {
    from: {
      opacity: 0,
      transform: isInverted
        ? 'translate(-100px, -0%) scaleY(1)'
        : 'translate(-50px, -100%) scaleY(0.9)',
    },
    enter: {
      opacity: 1,
      transform: isInverted
        ? 'translate(-120px, 0%) scaleY(1)'
        : 'translate(-20px, -100%) scaleY(1)',
    },
    leave: {
      opacity: 0,
      transform: isInverted
        ? 'translate(-100px, -0%) scaleY(1)'
        : 'translate(-20px, -100%) scaleY(1)',
    },
  })
  const logout = useStoreActions((s) => s.rocket.logout)
  const exit = useCallback(() => {
    logout()
    toggle(false)
  }, [logout, toggle])
  const isAuthenticated = useStoreState((s) => s.rocket.isAuthenticated)
  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <Portal key={key}>
              <Container ref={ref} style={props} top={top} left={left}>
                <ContentBody>
                  {isAuthenticated ? (
                    <StatusMenu
                      status={status}
                      exit={exit}
                    />
                  ) : (
                    <DisconnectMenu />
                  )}
                </ContentBody>
              </Container>
            </Portal>
          )
      )}
    </>
  )
}

export default Menu
