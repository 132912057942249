import React from 'react'
import { Paper, Typography, Button } from '@material-ui/core'
import { Formik } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { Auth } from 'aws-amplify'
import { useHistory } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'src/lib/EasyPeasy'
import FormTextField from './FormTextField'

const useStyles = makeStyles({
  loginContainer: {
    minHeight: '100vh',
    backgroundColor: '#FF7063',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formContainer: {
    width: 390,
    padding: 48,
  },
})

const handleLoginError = (error) => {
  switch (error.code) {
    default:
      alert(error.message)
  }
}

const SoftwareTokenMfa = () => {
  const classes = useStyles()
  const cognitoState = useStoreState((s) => s.auth.cognitoSetup)
  const currentAuthenticatedUser = useStoreActions(
    (s) => s.auth.currentAuthenticatedUser
  )
  const history = useHistory()

  if (!cognitoState) {
    history.push('/login')
  }

  const confirmSignIn = async ({ code }) => {
    try {
      await Auth.confirmSignIn(cognitoState, code, 'SOFTWARE_TOKEN_MFA')

      currentAuthenticatedUser()
      history.push('/')
    } catch (error) {
      console.log(error)
      handleLoginError(error)
    }
  }

  return (
    <div className={classes.loginContainer}>
      <Paper className={classes.formContainer}>
        <Typography align='center' variant='h4' gutterBottom>
          Insira o código
        </Typography>
        <Formik
          initialValues={{
            password: '',
          }}
          onSubmit={confirmSignIn}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormTextField
                label='Código de acesso'
                name='code'
                fullWidth
                margin='normal'
                autoFocus
              />
              <Button
                type='submit'
                color='primary'
                variant='contained'
                size='large'
                fullWidth
              >
                Entrar
              </Button>
            </form>
          )}
        />
      </Paper>
    </div>
  )
}

export default SoftwareTokenMfa
