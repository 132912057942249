import { createGlobalStyle } from './StyledComponents'

const GlobalStyles = createGlobalStyle`
  html, body { 
    margin:0;
    padding:0;
    width:100%;
    height:100%;
    overflow: auto;
  }
  body {
    background: #F4F3FA;
    color: rgba(0, 0, 0, 0.87);
    font-family: Roboto, Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  #root{
    /* width: 100vw; */
  }
  * {
    &::-webkit-scrollbar-track {
      /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 6px;
      background-color: #11c47d;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #747474;
    }
    ::-webkit-scrollbar-thumb:active {
      background: #11c47d;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bbbbbb;
    }
  }
`

export default GlobalStyles
