import { createMuiTheme } from '@material-ui/core'

export const theme = {
  primary: '#FF7063',
  grey: '#F4F3FA',
  palette: {
    primary: {
      main: '#FF7063',
      contrastText: '#fff',
    },
    secondary: {
      main: '#FF7063',
      contrastText: '#fff',
    },
    white: {
      main: '#fff',
      contrastText: '#555555',
    },
    gray: {
      light: '#c4c4c4',
    },
    red: {
      main: '#F97676',
    },
  },
  typography: {
    h2: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    h3: {
      fontSize: 14,
      fontWeight: 'bold',
    },
    h4: {
      fontSize: 14,
      fontWeight: 'bold',
      display: 'inline',
    },
    body1: {
      fontSize: 14,
      lineHeight: '24px',
    },
  },
  overrides: {},
}

export const muiTheme = createMuiTheme(theme as any)

export type ITheme = typeof theme
