import React, { useEffect, useState } from 'react'
import QRCode from 'qrcode.react'
import { Paper, Typography, Button, Box } from '@material-ui/core'
import { Formik } from 'formik'
import { Auth } from 'aws-amplify'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useStoreActions, useStoreState } from 'src/lib/EasyPeasy'
import FormTextField from './FormTextField'

const useStyles = makeStyles({
  loginContainer: {
    minHeight: '100vh',
    backgroundColor: '#FF7063',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formContainer: {
    width: 390,
    padding: 48,
  },
})

const handleLoginError = (error) => {
  switch (error.code) {
    default:
      alert(error.message)
  }
}

const MfaSetup = () => {
  const classes = useStyles()
  const cognitoState = useStoreState((s) => s.auth.cognitoSetup)
  const history = useHistory()
  const [qrcode, setQrcode] = useState(null)

  if (!cognitoState) {
    history.push('/login')
  }

  useEffect(() => {
    async function setupTotp() {
      const code = await Auth.setupTOTP(cognitoState)
      const str = `otpauth://totp/AWSCognito:admin-ton?secret=${code}&issuer=AWSCognito`
      setQrcode(str)
    }
    if (cognitoState) {
      setupTotp()
    }
  }, [cognitoState])

  const qrcodeTag = qrcode ? <QRCode value={qrcode} size={250} /> : <div />
  const currentAuthenticatedUser = useStoreActions(
    (s) => s.auth.currentAuthenticatedUser
  )
  const verifyTotpToken = async ({ code }) => {
    try {
      await Auth.verifyTotpToken(cognitoState, code)

      currentAuthenticatedUser()
      history.push('/')
    } catch (error) {
      console.log(error)
      handleLoginError(error)
    }
  }

  return (
    <div className={classes.loginContainer}>
      <Paper className={classes.formContainer}>
        <Typography align='center' variant='h4' gutterBottom>
          Configure o MFA
        </Typography>
        <Box textAlign='center'>{qrcodeTag}</Box>
        <Formik
          initialValues={{
            password: '',
          }}
          onSubmit={verifyTotpToken}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormTextField
                label='Código'
                name='code'
                fullWidth
                margin='normal'
              />
              <Button
                type='submit'
                color='primary'
                variant='contained'
                size='large'
                fullWidth
              >
                Entrar
              </Button>
            </form>
          )}
        />
      </Paper>
    </div>
  )
}

export default MfaSetup
