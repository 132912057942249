import { action, persist } from 'easy-peasy'

import AccessibilityStoreModel from './types'

const accessibilityStore: AccessibilityStoreModel = {
  tableBorders: false,
  toggleTableBorders: action((state) => {
    state.tableBorders = !state.tableBorders
  }),
}

export default persist(accessibilityStore, {
  whitelist: ['tableBorders'],
  storage: 'localStorage',
})
