import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ClassNames from 'classnames'
import { CircularProgress } from '@material-ui/core'
import Theme from '../helpers/theme'

const Loading = () => {
  const css = makeStyles(Theme)()

  const cssInnerLoading = ClassNames({
    [css.displayFlex]: true,
  })

  const cssLoadingIcon = ClassNames({
    [css.center]: true,
  })

  return (
    <div className={cssInnerLoading}>
      <CircularProgress className={cssLoadingIcon} />
    </div>
  )
}

export default Loading
