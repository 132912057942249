import React, { useMemo } from 'react'
import { useLocation } from 'react-use'
import pages from '../pages'
import { Items, Container } from './styles'

interface Props extends React.HTMLAttributes<HTMLAnchorElement> {
  to?: string
}

const getPrevious = (activePathname, myPath) => {
  const activeIndex = pages.findIndex((e) => e === activePathname)
  const myIndex = pages.findIndex((e) => e === myPath)
  if (activeIndex - 1 === myIndex) {
    return true
  }
  return false
}
const getNext = (activePathname, myPath) => {
  const activeIndex = pages.findIndex((e) => e === activePathname)
  const myIndex = pages.findIndex((e) => e === myPath)
  if (activeIndex + 1 === myIndex) {
    return true
  }
  return false
}

export const Item: React.FC<Props> = ({ children, to, ...props }) => {
  const location = useLocation()
  const isPreviousActive = useMemo(() => getPrevious(location.pathname, to), [
    location,
    to,
  ])
  const isNextActive = useMemo(() => getNext(location.pathname, to), [
    location,
    to,
  ])
  return (
    <Container
      {...(to ? { to } : { to: '#' })}
      $active={location.pathname === to}
      $isNextActive={isNextActive}
      $isPreviousActive={isPreviousActive}
      {...props}
    >
      {children}
    </Container>
  )
}

export default Items
