/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { TextField } from '@material-ui/core'
import { Field } from 'formik'

const CustomTextField = ({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => <TextField variant='outlined' {...props} {...field} />

const FormTextField = (props) => (
  <Field {...props} component={CustomTextField} />
)

export default FormTextField
