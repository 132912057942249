import React from 'react'
import { Auth } from 'aws-amplify'
import { Paper, Typography, Button } from '@material-ui/core'
import { useHistory, Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Formik } from 'formik'
import { useStoreActions } from 'src/lib/EasyPeasy'
import FormTextField from './FormTextField'
import './login.css';

const handleLoginError = (error) => {
  switch (error.code) {
    default:
      alert(error.message)
  }
}

const useStyles = makeStyles({
  loginContainer: {
    minHeight: '100vh',
    backgroundColor: '#FF7063',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formContainer: {
    width: 390,
    padding: 48,
  },
})

const Login = () => {
  const classes = useStyles()
  const history = useHistory()
  const newPasswordRequired = useStoreActions((s) => s.auth.newPasswordRequired)
  const mfaSetup = useStoreActions((s) => s.auth.mfaSetup)
  const softwareTokenMfa = useStoreActions((s) => s.auth.softwareTokenMfa)
  const currentAuthenticatedUser = useStoreActions(
    (s) => s.auth.currentAuthenticatedUser
  )

  const login = async ({ email, password }) => {
    try {
      const cognitoUser = await Auth.signIn(email, password)

      switch (cognitoUser.challengeName) {
        case 'NEW_PASSWORD_REQUIRED':
          newPasswordRequired(cognitoUser)
          history.push('/new-password-required')
          break
        case 'MFA_SETUP':
          mfaSetup(cognitoUser)
          history.push('/new-password-required')
          break
        case 'SOFTWARE_TOKEN_MFA':
          softwareTokenMfa(cognitoUser)
          history.push('/software-token-mfa')
          break
        default:
          currentAuthenticatedUser()
          history.push('/')
      }
    } catch (error) {
      console.log(error)
      handleLoginError(error)
    }
  }

  return (
    <div className={classes.loginContainer}>
      <Paper className={classes.formContainer}>
        <div onClick={window.legoMagic} id="legos" class="legos">
          <div class="lego lego-primary lego-top">
            <div class="top"></div>
          </div>
          <div class="lego lego-secondary lego-back">
            <div class="top"></div>
          </div>
          <div class="lego lego-primary lego-left">
            <div class="top"></div>
          </div>
          <div class="lego lego-primary lego-right">
            <div class="top"></div>
          </div>
        </div>
        {/* <Typography align='center' variant='h4' gutterBottom>
          Entrar
        </Typography> */}
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={login}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormTextField
                label='Email'
                name='email'
                fullWidth
                margin='normal'
                autoFocus
              />
              <FormTextField
                label='Senha'
                name='password'
                type='password'
                fullWidth
                margin='normal'
              />
              <Button
                margin='normal'
                type='submit'
                color='primary'
                variant='contained'
                size='large'
                fullWidth
              >
                Entrar
              </Button>
            </form>
          )}
        />
        <p>
          <Link to='/forgot-password'>Esqueceu a senha?</Link>
        </p>
      </Paper>
    </div>
  )
}

export default Login
