import { action } from 'easy-peasy'
import HeadersModel from './types'

const headersStore: HeadersModel = {
  size: 0,
  setSize: action((state, newSize) => {
    state.size = newSize
  }),
}

export default headersStore
