import React, { useState } from 'react'
import SlidingPanel from 'react-sliding-side-panel'
import '../../../node_modules/uikit/dist/css/uikit.min.css'
import './Sidebar.css'
import { Payload } from '../../types/JavascriptPayload'

const IntentSidebar = ({ intent, onSave, visible, onClose }) => {
  const [it, setIt] = useState(null)
  const [payload, setPayload] = useState(new Payload(''))
  const w = window as any

  return (
    <SlidingPanel panelClassName={"modal micromodal-slide is-open"} type={'right'} isOpen={visible} size={35} backdropClicked={onClose}>
      <div className={"modal__container"} style={{ backgroundColor: '#FFFFFF', width: '100%', height: '100%' }}>
      { intent && intent.displayName ? <iframe src={'./' + intent.displayName + '.html'} style={{width: '100%', height: '100%'}}></iframe> : <></> }
        {/* <header className={"modal__header"}>
          <h2 className={"modal__title"}>{intent ? intent.displayName : ""}</h2>
          <button type={"button"} className={"modal__close"} onClick={onClose}></button>
        </header>
        <div className={"modal__content"}>
          <p className={"uk-text-large uk-text-light"}>Inputs do Usuário</p>
          {
            intent && intent.trainingPhrases ? intent.trainingPhrases.map(tf => {
              let txt = "";
              tf.parts.forEach(p => txt += p.text)
              return (<><input name="training-phrase" className={"uk-input training-phrase-input"} type="text" value={txt}></input><br/></>)
            }) : <><input name="training-phrase" disabled={true} readOnly={true} className={"uk-input training-phrase-input"} type="text" value={"Fallback"}></input></>
          }
          <hr/>
          <p className="uk-text-large uk-text-light">Interações</p>
          <div id="conditions">
            <iframe src={'./chart.html'} style={{width: '100%'}}></iframe>
          </div>
          <button type="button" className="uk-button uk-button-secondary uk-button-small" id="new-condition"style={{marginRight: '10px'}}>Adicionar Condição</button>
          <button type="button" className="uk-button uk-button-secondary uk-button-small" id="new-action">Adicionar Ação</button>
        </div>
        <footer className="modal__footer">
          <button type="submit" className="uk-button uk-button-primary" id="save-intent-form" style={{marginRight: '10px'}}>Salvar</button>
          <button type="button" className="uk-button uk-button-danger drawer-cancel-button" data-micromodal-close="" aria-label="Close this dialog window">Cancelar</button>
        </footer> */}
      </div>
    </SlidingPanel>
  )
}

export default IntentSidebar
