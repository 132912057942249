import styled, { css } from 'src/lib/StyledComponents'
import { Link } from 'react-router-dom'

const activeCss = css`
  background-color: #f4f3fa;
  /* border-radius: 5px; */
  .MuiSvgIcon-root {
    color: #FF7063;
  }
`
const unactiveCss = css`
  &:hover {
    background-color: rgba(0,0,0,0.04);
  }
`

const radius = 15

export const Container = styled(Link)<{
  $active?: boolean
  $isNextActive?: boolean
  $isPreviousActive?: boolean
}>`
  display: flex;
  width: 100%;
  background-color: #FF7063;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1),
    border-bottom-right-radius 150ms cubic-bezier(0.4, 0, 0.2, 1),
    border-top-right-radius 150ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: background-color, border-bottom-right-radius,
    border-top-right-radius;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 0;
  .MuiSvgIcon-root {
    color: white;
  }

  ${(p) => (p.$active ? activeCss : unactiveCss)}

  border-bottom-right-radius: ${(props) =>
    props.$isPreviousActive ? radius : 0}px;
  border-top-right-radius: ${(props) => (props.$isNextActive ? radius : 0)}px;
  @media screen and (max-width: 500px) {
    border-top-right-radius: 0px;
    border-bottom-left-radius: ${(props) =>
      props.$isNextActive ? radius : 0}px;
    padding: 10px 10px;
  }
`

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f4f3fa;
  @media screen and (max-width: 500px) {
    margin: 0 auto;
  }
`
