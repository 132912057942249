import React from 'react'
import styled, { css } from 'src/lib/StyledComponents'
import { animated } from 'react-spring'
import { getColorFromStatus } from '../utils'

export const Container = styled(animated.div)<{
  top: number
  left: number
  ref: any
}>`
  display: flex;
  position: fixed;
  top: ${(p) => p.top}px;
  left: ${(p) => p.left}px;
  transform-origin: bottom;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #dcdcdc;
  background-color: white;
  z-index: 10000;
  box-sizing: border-box;
  overflow: hidden;
`

export const ContentBody = styled.div`
  /* margin: 5px 0px; */
  display: flex;
  flex-direction: column;
`

export const Title = styled.p`
  margin: 0;
  padding: 2px 10px;
  font-weight: 700;
  font-size: 1rem;
`
export const Exit = styled(Title)`
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`

export const StatusText = styled.p`
  font-size: 0.9rem;
  margin: 0;
  user-select: none;
`

export const Line = styled.div`
  width: 100%;
  margin: 4px 0;
  height: 1px;
  background-color: #c4c4c4;
`
const StatusBallSize = 10

export const StatusBall = styled.div<{ color: string }>`
  width: ${StatusBallSize}px;
  height: ${StatusBallSize}px;
  border-radius: 50%;
  background-color: ${(p) => p.color};
  margin: 0 5px;
`

type Status = 'busy' | 'online' | 'invisible' | 'away'

export const Status = styled(({ selectable, children, status, ...props }) => (
  <div {...props}>
    <StatusBall color={getColorFromStatus(status)} />
    <StatusText>{children}</StatusText>
  </div>
))<{ selectable?: boolean; status?: Status }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 3px 10px;
  padding-right: 20px;
  ${(p) =>
    p.selectable &&
    css`
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    `}
`

Status.defaultProps = {
  selectable: true,
  status: 'online',
}
