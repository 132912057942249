// import { Container, TopSection, Logo, BottomSection } from './styles'

import styled from 'src/lib/StyledComponents'
import Items from './Items'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100%;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`

export const Main = styled.main`
  flex: 1;
  padding: 10px 20px;
  height: fit-content;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  overflow-y: auto;
  max-height: 100vh;
  @media screen and (max-width: 500px) {
    max-height: calc(100vh - 50px);
    padding: 10px 5px;
  }
`
const Section = styled.div`
  display: flex;
  flex-direction: column;
`

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #FF7063;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  width: 100px;
  z-index: 90;
  @media screen and (max-width: 500px) {
    /* position: fixed; */
    /* position: fixed; */
    flex-direction: row;
    width: 100%;
    height: 50px;
    ${Section}, ${Items} {
      flex-direction: row;
      /* flex-wrap: wrap; */
      margin: 0 auto;
    }
    ${Items} {
      flex: 1;
    }
    flex-wrap: wrap-reverse;
    height: fit-content;
  }
`

export const TopSection = styled(Section)`
  @media screen and (max-width: 500px) {
    height: 50px;
  }
`

export const BottomSection = styled(Section)`
  @media screen and (max-width: 500px) {
    height: 50px;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
`

export const Logo = styled.img.attrs({
  src: '/icons/mini-fintalk.png',
})`
  width: 70%;
  margin: 10px auto;
  display: block;
  cursor: pointer;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(100% - 50px);
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`
